import styled from "styled-components";
import { Typography, Text as BaseText } from "components/UI";


export const Title = styled(Typography).attrs({
  size: "m" 
})`
`;

export const Text = styled(BaseText).attrs({
})`
margin-top: 1em;
`;

export const ContactContainer = styled.div`
  width:100%;
  padding: 32px;
  white-space: pre-line;
`;

export const Block = styled.div`
margin-bottom: 16px;
`;


export const MailLink = styled.a`

font-size: ${props => (props.theme.fonts.body.m.size)}; 
color: ${props => (props.theme.colors.text.default)};
font-weight: 600;
text-decoration: underline;
`;
