import styled from "styled-components";
import PhoneInput from "react-phone-input-2";
import hexToRgba from "hex-to-rgba";
export const StyledPhone = styled(PhoneInput)`

  && {
    &.react-tel-input {
      .form-control {
        height: 36px;
        /* font-size: 16px; */
        /* background: red; */

        /* border-radius: 5px; */
        /* width: 300px;
        outline: none; */
        /* padding: 18.5px 14px 18.5px 58px; */
        transition: box-shadow ease 0.25s, border-color ease 0.25s;

        border-radius: 4px;
        border: none;
        border-bottom: 2px solid white;
        background-color: rgba(0, 0, 0, 0.025);

        ${(props) => props.error && `box-shadow: 0 0 0 2px ${props.theme.colors.text.error};`};

        /* &:hover {
          border-color: #000;
        } */
        &:focus {
          border-color: transparent;
          background-color: white;
          box-shadow: 0 0 0 2px rgba(177, 181, 254, 0.6);
          ${(props) => props.error && `box-shadow: 0 0 0 2px ${props.theme.colors.text.error};`};
          
          & + div:before {
            color: #1976d2;
          }
        }

        &.invalid-number {
          box-shadow: 0 0 0 2px ${(props) => props.theme.colors.text.error};

          /* & + div:before {
            content: "Error";
            color: ${(props) => props.theme.colors.text.error};
            display: none;
            color: #f44336;
            width: 27px;
          } */
        }
      }

      .flag-dropdown {
        position: absolute;
        top: 0;
        bottom: 0;
        padding: 0;
        border-radius: 3px 0 0 3px;
        &:hover,
        &:focus {
          cursor: pointer;
        }
        &.open {
          z-index: 2;
        }
      }

      .selected-flag {
        outline: none;
        position: relative;
        width: 52px;
        height: 100%;
        padding: 0 0 0 11px;
        border-radius: 3px 0 0 3px;

        &:focus {
          .arrow {
            border-left-width: 4px;
            border-right-width: 4px;
            border-top: 5px solid #1976d2;
          }
        }
      }
      .country-list {
        outline: none;
        z-index: 1;
        list-style: none;
        position: absolute;
        padding: 0;
        margin: 10px 0 10px -1px;
        /* box-shadow: 1px 2px 18px rgba(0, 0, 0, 0.25); */
        box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.08), 0 2px 2px rgb(0 0 0 / 3%),
          0 4px 4px rgb(0 0 0 / 4%), 0 10px 8px rgb(0 0 0 / 5%),
          0 15px 15px rgb(0 0 0 / 6%), 0 30px 30px rgb(0 0 0 / 7%),
          0 70px 65px rgb(0 0 0 / 9%);

        background-color: none;
        width: 300px;
        max-height: 220px;
        overflow-y: scroll;

        background: linear-gradient(
          45deg,
          ${(props) => hexToRgba(props.theme.colors.bg.default, 0.45)} 0%,
          ${(props) => hexToRgba(props.theme.colors.bg.default, 0.85)} 100%
        );

        backdrop-filter: blur(6px);
        border-radius: 8px;
        .flag {
          left: 9px;
          top: 4px;
        }
        .divider {
          padding-bottom: 5px;
          margin-bottom: 5px;
          border-bottom: 1px solid #ccc;
        }
        .country {
          position: relative;
          margin: 4px;
          /* padding: 12px 9px 13px 46px; */
          border-radius: 4px;
          padding: 8px 5px 9px 42px;
          .dial-code {
            color: #6b6b6b;
          }
          &:hover {
            background-color: rgba(10, 20, 28, 0.09);
          }
          &.highlight {
            background-color: ${(props) =>
              hexToRgba(props.theme.colors.text.purple, 0.2)};
            color: ${(props) => props.theme.colors.text.purple};
            font-weight: bold;
          }
        }

        .flag {
          margin-right: 7px;
          margin-top: 2px;
        }

        .country-name {
          margin-right: 6px;
        }

        .search {
          background-color: ${(props) => props.theme.colors.bg.default};
          border-bottom: 1px solid
            ${(props) => props.theme.colors.border.default};
          padding: 10px 0 10px 10px;
        }
        .search-emoji {
          display: none;
          font-size: 15px;
        }
        .search-box {
          border: none;
          border-bottom: 2px solid white;
          background-color: rgba(0, 0, 0, 0.025);
          border-radius: 4px;
          height: 38px;
          padding: none;
          outline: none;
          &:hover {
            /* border-color: #505050; */
          }

          &:focus {
            /* border-color: #505050; */
            box-shadow: 0 0 0 2px rgba(177, 181, 254, 0.6);
            background-color: white;
          }
        }
        .no-entries-message {
          padding: 7px 10px 11px;
          opacity: 0.7;
        }
      }

      .special-label {
        background: transparent;
        visibility: hidden;
      }
    }
  }
`;
