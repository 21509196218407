import React from "react";
import * as S from "./style";
 import "react-phone-input-2/lib/material.css";



const PhoneInput = (props) => {
  const { error, ...other } = props;

  return (
      <S.StyledPhone  countryCodeEditable={true} error={error} placeholder={""} {...other}  />
  );
};

export default PhoneInput;
