import * as React from "react";
import ContactSales from "components/landing-page/ContactSales";
// import ThemeProvider from "./ThemeProvider";
// markup

import { graphql } from 'gatsby'

const ContactSalesPage = ({}) => {
  return <ContactSales/>
};

export default ContactSalesPage;

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

