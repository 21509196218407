import styled from "styled-components";
import { Grid } from "@mui/material";
import {
  Button as BaseButton,
  Select as BaseSelect,
  SelectItem as BaseSelectItem,
  Typography,
} from "components/UI";

export const Title = styled(Typography).attrs({
  size: "xl",
})`
  background: linear-gradient(90deg, #636bff 0%, #D058BB 100%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 0.5em;
`;

export const SelectItem = styled(BaseSelectItem)`
  &.new-class {
    border-top: 1px solid ${(props) => props.theme.colors.border.default};
    padding-top: 10px;
    padding-bottom: 10px;
  }
`;

export const Select = styled(BaseSelect).attrs({
  paperProps: {
    paddingBottom: "0px",
  },
})`
  height: 36px;
  width: 236px;
  flex-grow: auto;
  padding-right: 5px;
  margin-bottom: 0;
`;

export const ContactContainer = styled.div`
  padding: 32px 32px;
  border-radius: 15px;
  box-shadow: ${(props) => props.theme.styles.boxShadow.paper};
  border: 1px solid ${(props) => props.theme.colors.border.default};
  width: 100%;
`;



export const ContactButton = styled(BaseButton).attrs((props) => ({
  variant: "contained",
  color: "primary",
}))`
  height: 46px;
  font-size: 18px;
  border-radius: 13px;
  margin-top: 20px;
  padding: 0px 40px;
`;

export const FormGroup = styled.form`
  width: 100%;

  /* @media only screen and (max-width: 1045px) {
    max-width: 100%;
    margin-top: 2rem;
  } */

  > * {
    margin-bottom: 8px;
  }
`;

export const ButtonContainer = styled.div`
  text-align: end;
  position: relative;

  @media only screen and (max-width: 414px) {
    padding-top: 0.75rem;
  }
`;

export const Row = styled(Grid).attrs({
  direction: "row",
  container: true,
  justify: "space-between",
})``;

export const Col = styled(Grid).attrs({
  item: true,
  container: true,
  direction: "column",
})``;
