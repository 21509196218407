import React from "react";
import * as S from "./style";
import { useTranslation } from "gatsby-plugin-react-i18next";
import MainLayout from "components/MainLayout";

import ContactForm from "./ContactForm";
import ContactInfo from "./ContactInfo";

const ContactSales = () => {
  const { t } = useTranslation();

  function handleSubmit(e) {
    e.preventDefault();
  }

  const content = t("contact_sales", { returnObjects: true });

  return (
    <MainLayout>
      <S.MainContainer>
        <S.Container>
          <S.Row>
          <S.Col lg={8} md={8} sm={12} xs={12}>
            <ContactForm
              id="contact"
              content={content.form}
              handleSubmit={handleSubmit}
            />
          </S.Col>
          <S.Col lg={4} md={4} sm={12} xs={12}>
            <ContactInfo content={content.info} />
          </S.Col>
          </S.Row>
        </S.Container>
      </S.MainContainer>
    </MainLayout>
  );
};

export default ContactSales;
