// import { Row, Col } from "antd";
import React from "react";
// import { Slide, Zoom } from "react-awesome-reveal";
import * as S from "./style";



const ContactInfo = ({ title, content,  t }) => {


  return (
    <S.ContactContainer>

      <S.Block>
        <S.Title>{content["Need customer support?"]}</S.Title>
        <S.Text>
          {content["Questions about your existing Theos Enterprise?"]}
        </S.Text>
        <S.MailLink href="mailto:contact@theos.ai?subject=Enterprise Support">
          {content["Contact Enterprise support."]}
        </S.MailLink>

      </S.Block>
      <S.Text>{content["Questions about Theos.ai?"]}</S.Text>
        <S.MailLink href="mailto:contact@theos.ai?subject=General Support">
          {content["Contact general support."]}
        </S.MailLink>
      <S.Block>
        
      </S.Block>
      <S.Block>
        <S.Title>{content["Theos mailing address"]}</S.Title>
        <S.Text>{content.address}</S.Text>
      </S.Block>
      <S.Block>
        <S.Title>{content["Phone Number"]}</S.Title>
        <S.Text>{content.phone}</S.Text>
      </S.Block>
    </S.ContactContainer>
  );
};

export default ContactInfo;
