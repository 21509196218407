import styled from "styled-components";


export const Label = styled.label`
  font-size: ${(props) => props.theme.fonts.title.xs.size};
  font-weight: ${(props) => props.theme.fonts.weight.bold};
  color: ${(props) => props.valid ? props.theme.colors.text.neumo : props.theme.colors.text.error};
`;


export const LabelContainer = styled.div`

height: ${(props) => `calc( ${props.theme.fonts.title.xs.size} * 2.5)`};
display: flex;

justify-content: space-between;
align-items: center;
padding-bottom: 20px;
`;

export const LabelBottomContainer = styled.div`
/* height: ${(props) => props.showBottomLabel ? `calc( ${props.theme.fonts.title.xs.size * 3} )`: '0px'}; */
height: ${(props) => props.showBottomLabel ? `calc( ${props.theme.fonts.title.xs.size} * 2.5 )` : '0px'};
transition: height 200ms ease-in-out;
display: flex;
/* background-color: blue; */
/* display:${(props) => (props.valid !== null && props.valid && (!props.focused)) && "none"};   */
justify-content: space-between;
align-items: center;
`;

export const LabelBottom = styled.label`
  font-size: ${(props) => props.theme.fonts.title.xs.size};
  /* background-color: red; */
  font-weight: ${(props) => props.theme.fonts.weight.light};
  color: ${(props) => props.valid ? props.theme.colors.text.light : props.theme.colors.text.error};
`;


export const Container = styled.label`
display: flex;
flex-direction: column;
margin-top:20px;
`;