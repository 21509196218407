// import { Row, Col } from "antd";
import { LabelBottom } from "components/UI/Common/LandingPage/Input/style";
import React from "react";

import * as S from "./style";


function InputWrapper(props) {
    const { children, label, error, errorLabel } = props;

  return (

   <S.Container>
    <S.LabelContainer>
    <S.Label valid={!error}>{label}</S.Label>
    </S.LabelContainer>
{children}
<S.LabelBottomContainer showBottomLabel={error}>
{error && <S.LabelBottom>{errorLabel}</S.LabelBottom>}
</S.LabelBottomContainer>
   </S.Container>
  );
};

export default InputWrapper;
